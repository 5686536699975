// WeatherFacts.js
import React from "react";
import './Fact.css';
import sunImage from './images/thesun.jpg';
import rainImage from './images/raindrop.jpg';
import snowImage from './images/snowflake.webp';
import windImage from './images/wind.jpg';

import { FaSun, FaCloudRain, FaSnowflake, FaWind } from 'react-icons/fa';

const WeatherFacts = () => {
  const facts = [
    {
      icon: <FaSun size={24} color="#FFD700" />,
      image: sunImage,
      fact: "Did you know? The Sun is a hot star that’s 93 million miles away. And yet, your phone still overheats in it! 😂😂😂",
    },
    {
      icon: <FaCloudRain size={24} color="#1E90FF" />,
      image: rainImage,
      fact: "Raindrops aren’t actually teardrop-shaped! They're more like hamburger buns. Now that's food for thought! 🍔🍔🤔",
    },
    {
      icon: <FaSnowflake size={24} color="#00BFFF" />,
      image: snowImage,
      fact: "Snowflakes can have up to 200 crystals in unique shapes. Just like your ‘unique’ excuse to skip shoveling!",
    },
    {
      icon: <FaWind size={24} color="#7B68EE" />,
      image: windImage,
      fact: "Wind travels faster at higher altitudes! But don’t get any ideas; you’re still not faster than that hat flying off your head!",
    },
  ];

  return (
    <div className="weather-facts-container">
      <h2 className="facts-header">Did You Know? Fun Weather Facts!</h2>
      <div className="facts-wrapper">
        {facts.map((fact, index) => (
          <div className="fact-card" key={index}>
            <div className="fact-image-wrapper">
              <img src={fact.image} alt="weather type" className="fact-image" />
            </div>
            <div className="fact-content">
              <div className="fact-icon">{fact.icon}</div>
              <p className="fact-text">{fact.fact}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WeatherFacts;
