import React from 'react';
import Contactimg from './images/contact.png';
import './Contact.css';

const Contact = () => {
    return (
        <section className="contact-container">
            <div className="contact-heroes">
                <div className="heroes-content">
                    <h1>Get in Touch</h1>
                    <p>We’re here to help! Reach out to us with any questions, feedback, or suggestions, and we'll get back to you as soon as possible.</p>
                    <p>Our team is dedicated to providing you with the best support and making your experience seamless.</p>
                </div>
                <div className="heroes-image">
                    <img src={Contactimg} alt="Contact Communication" />
                </div>
            </div>

            <div className="contact-hero">
                <p>We’d love to hear from you. Reach out with any questions or feedback!</p>
            </div>

            <form className="contact-form">
                <div className="form-group">
                    <label>
                        <span className="icon">👤</span>
                        <input type="text" placeholder="Your Name" />
                    </label>
                </div>
                <div className="form-group">
                    <label>
                        <span className="icon">📧</span>
                        <input type="email" placeholder="Your Email" />
                    </label>
                </div>
                <div className="form-group">
                    <label>
                        <span className="icon">💬</span>
                        <textarea placeholder="Your Message"></textarea>
                    </label>
                </div>
                <button type="submit" className="submit-btn">Send Message</button>
            </form>

            <div className="contact-details">
                <div className="contact-info">
                    <h2>Find us here</h2>
                    <p><span>📞</span> +123 456 7890</p>
                    <p><span>📍</span> 123 Weather Street, Cloud City, Lusaka</p>
                    <p><span>📧</span> contact@weatherapp.com</p>
                </div>

                <div className="map-container">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15602.501109412613!2d28.28220243676699!3d-15.387525543391376!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1940f845b6b2f1a1%3A0x46b31a8a7a3e470b!2sLusaka%2C%20Zambia!5e0!3m2!1sen!2sus!4v1616281610147!5m2!1sen!2sus"
                        title="Lusaka Location Map"
                        width="100%"
                        height="300"
                        style={{ border: 0 }}
                        allowFullScreen=""
                        loading="lazy"
                    ></iframe>
                </div>
            </div>
        </section>
    );
};

export default Contact;
