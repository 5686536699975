import React, { useEffect } from 'react';
import './WeatherAlerts.css';

function WeatherAlerts({ city, alerts = [], loading, error }) {
  useEffect(() => {
    console.log("City Name:", city);
    console.log("Alerts:", alerts);
    console.log("Loading:", loading);
    console.log("Error:", error);
  }, [city, alerts, loading, error]);

  return (
    <div className="alerts-section">
      <div className="awareness-section">
        <h2>Weather Safety Tips</h2>
        <p>Stay informed and prepared to handle severe weather conditions in your area. Following weather alerts can help keep you and your loved ones safe!</p>
      </div>

      {!city ? (
        <p className="info-text">Enter a city to see potential weather alerts here and stay prepared!</p>
      ) : loading ? (
        <p className="info-text">Loading alerts...</p>
      ) : error ? (
        <p className="info-text">Error loading weather alerts. Please try again.</p>
      ) : alerts.length === 0 ? (
        <p className="info-text">No active weather alerts for {city}. No serious weather alerts for this area.</p>
      ) : (
        <div>
          <h2 className="alert-header">Weather Alerts for {city}</h2>
          {alerts.map((alert, index) => (
            <div key={index} className="alert-item">
              <h3 className="alert-title">{alert.event} for {city}</h3>
              <p className="alert-description">{alert.description || "---"}</p>
              <p><strong>Active from:</strong> {new Date(alert.start * 1000).toLocaleString()}</p>
              <p><strong>Until:</strong> {new Date(alert.end * 1000).toLocaleString()}</p>
              <p><strong>Issued by:</strong> {alert.sender_name || "---"}</p>
            </div>
          ))}
          <h3 className="most-serious">
            Most Serious Alert: {alerts.length > 0 ? alerts[0].event : "---"}
          </h3>
        </div>
      )}

      <div className="preparedness-section">
        <h2>Stay Prepared</h2>
        <ul>
          <li>Monitor local forecasts for updates.</li>
          <li>Have an emergency kit ready with essentials.</li>
          <li>Know your evacuation routes in case of severe weather.</li>
          <li>Secure outdoor objects that could become hazards in strong winds.</li>
        </ul>
      </div>
    </div>
  );
}

export default WeatherAlerts;
