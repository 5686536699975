import { useState, useEffect } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './Navbar';
import HeroSection from './Hero';
import WeatherOverview from './WeatherOverview';
import WeatherTips from './WeatherTips';
import WeatherFacts from './Facts';
import CitySection from './Cities';
import About from './About';
import Contact from './Contact';
import Services from './Services';
import WeatherAlerts from './WeatherAlerts';
import Footer from './Footer';
import emojiImage from './images/emoji1.png';
import './App.css';

function App() {

  const initialWeatherState = {
    baseTemperature: null,
    temperature: null,
    windSpeed: null,
    feelslike: null,
    unit: '°C',
    currentWeather: null,
    icon: null,
    humidity: null,
    pressure: null,
    city: null,
  };

  const [weatherData, setWeatherData] = useState(initialWeatherState);
  const [alerts, setAlerts] = useState([]);
  const [error, setError] = useState('');
  const [time, setTime] = useState(new Date().toLocaleTimeString());
  const [savedCities, setSavedCities] = useState([]);
  const [currentWeather, setCurrentWeather] = useState({
    temperature: 'Temperature',
    unit: ' ',
    image: 'default-weather-image-url',
    condition: 'City Weather',
    description: 'Add a city to check its current weather',
  });

  const handleCitySave = (city) => {
    if (!savedCities.includes(city)) { // Check if city is already saved
      setSavedCities([...savedCities, city]);
    }
  };

  // Function to delete a city from the savedCities array
  const handleDeleteCity = (city) => {
    setSavedCities(savedCities.filter(savedCity => savedCity !== city));
  };

  const fetchWeatherData = async (city) => {
    if (!city) return;
    try {
      const response = await axios.get(`https://weather-update-app-2.onrender.com/api/weather?city=${city}`);
      const { data } = response;
      setWeatherData({
        ...data,
        baseTemperature: data.temperature,
        temperature: data.temperature,
        unit: '°C',
      });
      setError('');
    } catch (error) {
      setError('Failed to fetch weather data');
      setWeatherData(initialWeatherState);
    }
  };

  const handleGetWeather = async (city) => {
    if (!city) return;
    try {
      const response = await axios.get(`https://weather-update-app-2.onrender.com/api/weather?city=${city}`);
      const { data } = response;
      setWeatherData({
        ...data,
        baseTemperature: data.temperature,
        temperature: data.temperature,
        unit: '°C',
      });
      setCurrentWeather({
        image: `weather-image-for-${city}`,
        condition: data.currentWeather,
        temperature: data.temperature,
        unit: '°C',
        description: `Currently the weather in ${city} is ${data.currentWeather} please adhere to the advice below in the weather tips section.`,
      });

      setError('');
    } catch (error) {
      setError('Failed to fetch weather data');
    }
  };

  const convertToFahrenheit = () => {
    if (weatherData.baseTemperature !== null) {
      const fahrenheit = (weatherData.baseTemperature * 9) / 5 + 32;
      setWeatherData((prevData) => ({ ...prevData, temperature: fahrenheit.toFixed(2), unit: '°F' }));
    }
  };

  const convertToKelvin = () => {
    if (weatherData.baseTemperature !== null) {
      const kelvin = weatherData.baseTemperature + 273.15;
      setWeatherData((prevData) => ({ ...prevData, temperature: kelvin.toFixed(2), unit: 'K' }));
    }
  };

  useEffect(() => {
    const timer = setInterval(() => setTime(new Date().toLocaleTimeString()), 1000);
    return () => clearInterval(timer);
  }, []);

  const getBackgroundClass = (weatherDescription) => {
    const description = weatherDescription?.toLowerCase() || '';
    if (description.includes('clear')) return 'clear-sky-background';
    if (description.includes('scattered')) return 'scattered-background';
    if (description.includes('few')) return 'few-background';
    if (description.includes('fog')) return 'foggy-background';
    if (description.includes('smoke')) return 'smoke-background';
    if (description.includes('rain')) return 'rain-background';
    if (description.includes('cloud')) return 'cloudy-background';
    if (description.includes('snow')) return 'snow-background';
    if (description.includes('thunderstorm')) return 'thunderstorm-background';
    if (description.includes('haze')) return 'haze-background';
    if (description.includes('sunny')) return 'sunny-background';
    if (description.includes('wind')) return 'windy-background';
    return 'default-background';
  };

  const backgroundClass = getBackgroundClass(weatherData.currentWeather);
  

  return (
    <div className="App">
      <Navbar onCitySubmit={fetchWeatherData} />

      <Routes>
        <Route path="/" element={
          <div className="homepage-content">
            <div className={`container ${backgroundClass}`}>
              {error && <p className="error-message">{error}</p>}
              <div className="weather-data-card">
                <h1>Time: {time}</h1>
                <h2>{weatherData.temperature ? `${weatherData.city}: ${weatherData.temperature} ${weatherData.unit}` : "Enter City to Get Today's Weather"}</h2>
                <p>{weatherData.city || 'the city'} is currently experiencing {weatherData.currentWeather || '---'}. Check weather overview for more specific details and stay prepared</p>
                <img className='emoji' src={emojiImage} />
              </div>
            </div>
            <WeatherOverview weatherData={weatherData} />
            <HeroSection
              temperature={weatherData.temperature}
              unit={weatherData.unit}
              convertToFahrenheit={convertToFahrenheit}
              convertToKelvin={convertToKelvin}
            />
            <CitySection
              currentWeather={currentWeather}
              onCitySave={handleCitySave}
              savedCities={savedCities}
              onGetWeather={handleGetWeather}
              onDeleteCity={handleDeleteCity}
            />
            <WeatherFacts />
            <WeatherTips currentWeather={weatherData.currentWeather || ''} />
          </div>
        } />
        <Route path='/weatheralerts' element={<WeatherAlerts lat={weatherData.lat} lon={weatherData.lon} city={weatherData.city} />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>

      <Footer />
    </div>
  );
}


export default App;
