import React, { useState } from 'react';
import { FaPlusCircle, FaTrashAlt, FaSyncAlt } from 'react-icons/fa'; // Renaming icons
import './Cities.css';

const CitySection = ({ currentWeather, onCitySave, savedCities, onGetWeather, onDeleteCity }) => {
  const [cityInput, setCityInput] = useState('');

  const getBackgroundClass = (weatherDescription) => {
    const description = weatherDescription?.toLowerCase() || '';
    if (description.includes('clear')) return 'clear-sky-background';
    if (description.includes('scattered')) return 'scattered-background';
    if (description.includes('few')) return 'few-background';
    if (description.includes('rain')) return 'rain-background';
    if (description.includes('cloud')) return 'cloudy-background';
    if (description.includes('snow')) return 'snow-background';
    if (description.includes('thunderstorm')) return 'thunderstorm-background';
    if (description.includes('haze')) return 'haze-background';
    if (description.includes('sunny')) return 'sunny-background';
    if (description.includes('wind')) return 'windy-background';
    return 'default-background';
  };

  const handleCityInput = (e) => setCityInput(e.target.value);

  const handleAddCity = () => {
    if (cityInput.trim()) {
      onCitySave(cityInput);
      setCityInput('');
    }
  };

  return (
    <div className="city-section">
      <div className={`weather-display ${getBackgroundClass(currentWeather?.description)}`}>
        <div className="weather-info">
          <h1>{currentWeather?.temperature || "---"} {currentWeather?.unit}</h1>
          <h2>{currentWeather?.condition || "---"}</h2>
          <p>{currentWeather?.description || `The weather in ${cityInput || '---'} is currently ---.`}</p>
        </div>
      </div>
      <div className="saved-cities">
        <h2>Manage Your Favorite Cities 🌍</h2>
        <p>Save your go-to cities here for quick and easy weather updates!</p>
        <div className="city-input-container">
          <input
            type="text"
            value={cityInput}
            onChange={handleCityInput}
            placeholder="Enter City Name"
            className="city-input"
          />
          <FaPlusCircle onClick={handleAddCity} className="icon add-icon" title="Add City" /> {/* Renamed */}
        </div>
        <ul>
          {savedCities.map(city => (
            <li key={city} className="city-item">
              <span>{city}</span>
              <div className="city-actions">
                <FaSyncAlt onClick={() => onGetWeather(city)} className="icon refresh-icon" title="Get Weather" />
                <FaTrashAlt onClick={() => onDeleteCity(city)} className="icon delete-icon" title="Delete City" />
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CitySection;
