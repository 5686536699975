import { FaCompass, FaGlobe, FaReact, FaCloudSun, FaBell, FaThermometerHalf, FaMapMarkedAlt, FaLayerGroup } from "react-icons/fa";
import './About.css';
import { Link } from "react-router-dom";

function AboutUs() {
  return (
    <div className="about-us">
      {/* Hero Section */}
      <section className="about-hero">
        <h1>Your Trusted Weather Partner</h1>
        <p>Reliable and real-time weather updates to plan your day confidently.</p>
        <button className="cta-button"><Link to="/Services">Learn More</Link></button>
      </section>

      {/* Mission & Vision */}
      <section className="mission-vision">
        <div className="card">
          <FaCompass className="icon" />
          <h2>Our Mission</h2>
          <p>Deliver accessible and accurate weather insights to everyone, everywhere.</p>
        </div>
        <div className="card">
          <FaGlobe className="icon" />
          <h2>Our Vision</h2>
          <p>Empowering your plans and decisions with reliable weather information.</p>
        </div>
      </section>

<section className="tech-used">
  <h2>Technologies We Use</h2>
  <div className="tech-grid">
    <div className="tech-item">
      <FaReact className="tech-icon" />
      <h3>React</h3>
      <div className="tech-modal">
        <p>React provides a seamless user experience, helping us build interactive and dynamic user interfaces.</p>
      </div>
    </div>
    <div className="tech-item">
      <FaCloudSun className="tech-icon" />
      <h3>OpenWeather API</h3>
      <div className="tech-modal">
        <p>OpenWeather API enables real-time weather data, ensuring timely and accurate information for our users.</p>
      </div>
    </div>
    <div className="tech-item">
      <FaMapMarkedAlt className="tech-icon" />
      <h3>Geolocation</h3>
      <div className="tech-modal">
        <p>Geolocation services personalize weather updates, providing location-based insights for travel and safety.</p>
      </div>
    </div>
    <div className="tech-item">
      <FaLayerGroup className="tech-icon" />
      <h3>Data Layering</h3>
      <div className="tech-modal">
        <p>Data layering ensures accuracy by combining multiple data sources for a comprehensive weather report.</p>
      </div>
    </div>
  </div>
</section>


      {/* User Benefits */}
<section className="benefits">
  <h2>Why Choose Us</h2>
  <div className="benefit-grid">
    <div className="benefit-item">
      <FaBell className="benefit-icon" />
      <p>Stay informed with real-time alerts and notifications.</p>
    </div>
    <div className="benefit-item">
      <FaThermometerHalf className="benefit-icon" />
      <p>Precise temperature tracking at your fingertips.</p>
    </div>
    <div className="benefit-item">
      <FaCloudSun className="benefit-icon" />
      <p>Accurate predictions for safe travel planning.</p>
    </div>
    <div className="benefit-item">
      <FaMapMarkedAlt className="benefit-icon" />
      <p>Localized updates for all your favorite destinations.</p>
    </div>
  </div>
</section>

      {/* Fun Facts Section */}
      <section className="fun-facts">
        <h2>Did You Know?</h2>
        <div className="fact-item">
          <p>Lightning heats the air it passes through to 30,000 K – that’s five times hotter than the sun!</p>
        </div>
        <div className="fact-item">
          <p>One inch of rain is equal to about 10 inches of snow.</p>
        </div>
        <div className="fact-item">
          <p>Clouds can weigh over a million pounds!</p>
        </div>
        <div className="fact-item">
          <p>The highest temperature ever recorded on Earth was 56.7°C in Death Valley, California.</p>
        </div>
      </section>
    </div>
  );
}

export default AboutUs;
