import React from 'react';
import './WeatherOverview.css';

const WeatherOverview = ({ weatherData }) => {
  // Optional chaining to safely access properties
  const city = weatherData?.city || 'City';
  const temperature = weatherData?.temperature || '---';
  const unit = weatherData?.unit || '°C';
  const windSpeed = weatherData?.windSpeed || '---';
  const humidity = weatherData?.humidity || '---';
  const pressure = weatherData?.pressure || '---';

  return (
    <div className="weather-overview">
      <div className="weather-header">
        <h2>Weather Overview</h2>
      </div>
      <p className="weather-description">
        Current weather details for {city}:
      </p>

      <div className="weather-info">
        <div className="weather-info-item">
          <i className="info-icon">🌡️</i>
          <div className="info-details">
            <h3>Temperature</h3>
            <p>{`${temperature} ${unit}`}</p>
          </div>
        </div>

        <div className="weather-info-item">
          <i className="info-icon">💨</i>
          <div className="info-details">
            <h3>Wind Speed</h3>
            <p>{`${windSpeed} m/s`}</p>
          </div>
        </div>

        <div className="weather-info-item">
          <i className="info-icon">💧</i>
          <div className="info-details">
            <h3>Humidity</h3>
            <p>{`${humidity}%`}</p>
          </div>
        </div>

        <div className="weather-info-item">
          <i className="info-icon">🌡️</i>
          <div className="info-details">
            <h3>Pressure</h3>
            <p>{`${pressure} hPa`}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WeatherOverview;
