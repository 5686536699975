import React, { useState } from 'react';
import {
    FaThermometerHalf, FaMapMarkedAlt, FaBell, FaCloudSun, FaQuestionCircle,
    FaCity, FaShieldAlt, FaMobileAlt, FaInfoCircle
} from 'react-icons/fa';
import './Services.css';

const Services = () => {
    const [activeQuestion, setActiveQuestion] = useState(null);

    const [activeModal, setActiveModal] = useState(null);

    const services = [
    {
      icon: <FaThermometerHalf />,
      title: 'Real-Time Weather Tracking',
      description: 'Stay up-to-date with live weather alerts and changes as they happen.',
      details: 'Our real-time weather tracking system provides minute-by-minute updates, ensuring you have the latest data. Perfect for daily planning and staying safe in changing conditions.',
      background: 'linear-gradient(135deg, #0072ff, #00c6ff)'
    },
    {
      icon: <FaMapMarkedAlt />,
      title: 'Geolocation Accuracy',
      description: 'Get precise, location-based updates no matter where you are.',
      details: 'Our geolocation services use advanced technology to deliver precise weather data specific to your exact location, whether you are on a hike or in the city.',
      background: 'linear-gradient(135deg, #ff7a18, #af002d 85%)'
    },
    {
      icon: <FaBell />,
      title: 'Instant Alerts',
      description: 'Receive alerts for severe weather events, keeping you and your loved ones safe.',
      details: 'Receive immediate notifications about severe weather, including storm warnings and extreme conditions, allowing you to take necessary precautions promptly.',
      background: 'linear-gradient(135deg, #ff4e50, #f9d423)'
    },
    {
      icon: <FaCloudSun />,
      title: 'Travel Forecasts',
      description: 'Plan trips with confidence, knowing the weather outlook in advance.',
      details: 'Travel forecasts give you an extended look at weather patterns, making it easy to plan trips with peace of mind.',
      background: 'linear-gradient(135deg, #7b4397, #dc2430)'
    }
  ];

  const openModal = (index) => setActiveModal(index);
  const closeModal = () => setActiveModal(null);


  const faqs = [
    {
      question: 'How accurate is the weather data?',
      answer: 'Our weather data is sourced from reliable providers, updated frequently to ensure you receive the latest and most accurate information available.',
    },
    {
      question: 'Can I receive alerts for severe weather?',
      answer: 'Yes, you can enable notifications to receive alerts for severe weather conditions in your area, including storms, heavy rainfall, and more.',
    },
    {
      question: 'Is there a mobile version available?',
      answer: 'Our service is fully responsive and can be accessed on any device, including smartphones and tablets.',
    },
  ];

  const toggleQuestion = (index) => {
    setActiveQuestion(activeQuestion === index ? null : index);
  };

  return (
    <section className="services">
      <div className="services-header">
        <div className="header-square">
          <h1>Our Exceptional Services</h1>
          <p>
            Discover a range of innovative solutions tailored to meet the unique needs of our clients.
            From precise weather updates to comprehensive geolocation services, we’re here to keep you informed.
          </p>
        </div>
      </div>

      <div className="service-grid">
        {services.map((service, index) => (
          <div
            key={index}
            className="service-card"
            style={{ background: service.background }}
            onClick={() => openModal(index)}
          >
            <div className="service-icon">{service.icon}</div>
            <div className="service-description">
              <h3>{service.title}</h3>
              <p>{service.description}</p>
              <button className="learn-more-btn">Learn More</button>
            </div>
          </div>
        ))}
      </div>

      {activeModal !== null && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-btn" onClick={closeModal}>✕</button>
            <h3>{services[activeModal].title}</h3>
            <p>{services[activeModal].details}</p>
          </div>
        </div>
      )}

      <div className="testimonials">
        <h3>What Our Users Say</h3>
        <div className="testimonial-card">
          <p>"The accuracy is unmatched! I always feel prepared for whatever the weather throws at me."</p>
          <p><strong>- Sarah T.</strong></p>
        </div>
        <div className="testimonial-card">
          <p>"I love the instant alerts for severe weather. It’s a lifesaver, literally!"</p>
          <p><strong>- Jake M.</strong></p>
        </div>
      </div>

      <section className="faq-section">
      <h2>Frequently Asked Questions</h2>
      <div className="faq-list">
        {faqs.map((faq, index) => (
          <div key={index} className="faq-item">
            <button className="faq-question" onClick={() => toggleQuestion(index)}>
              {faq.question}
            </button>
            {activeQuestion === index && (
              <div className="faq-answer">
                <p>{faq.answer}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </section>

    </section>
  );
};

export default Services;
