import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome, faInfoCircle, faConciergeBell,
  faPhone, faSearch, faExclamationTriangle, faPaperPlane
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import './Navbar.css';

const Navbar = ({ onCitySubmit }) => {
  const [city, setCity] = useState('');
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (city.trim()) onCitySubmit(city);
    setCity(''); // Reset the input field
    setIsSearchOpen(false); // Close search bar after submission
  };

  return (
    <nav className="navbar">
      <div className="navbar-header">
        <h1 className="navbar-title">Weather Update App</h1>
        <button className="search-toggle" onClick={() => setIsSearchOpen(!isSearchOpen)}>
          <FontAwesomeIcon icon={faSearch} />
        </button>
        <div className={`navbar-search ${isSearchOpen ? 'open' : ''}`}>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              className="search-bar"
              placeholder="Enter city"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
            {/* Icon for desktop, text button for mobile */}
            <button type="submit" className="submit-icon">
              <FontAwesomeIcon icon={faPaperPlane} className="desktop-icon" />
              <span className="mobile-button-text">Get Weather</span>
            </button>
          </form>
        </div>
      </div>

      <ul className="navbar-menu">
        <li><Link to="/"><FontAwesomeIcon icon={faHome} /><span>Home</span></Link></li>
        <li><Link to="/weatheralerts"><FontAwesomeIcon icon={faExclamationTriangle} /><span>Weather Alerts</span></Link></li>
        <li><Link to="/About"><FontAwesomeIcon icon={faInfoCircle} /><span>About Us</span></Link></li>
        <li><Link to="/Services"><FontAwesomeIcon icon={faConciergeBell} /><span>Services</span></Link></li>
        <li><Link to="/Contact"><FontAwesomeIcon icon={faPhone} /><span>Contact Us</span></Link></li>
      </ul>
    </nav>
  );
};

export default Navbar;
