// WeatherTips.js
import React from "react";
import './WeatherTips.css';
import snow from './images/snow.jpg';
import clear from './images/clear-skies.jpg';
import rain from './images/rainybaby2.jpg';
import thunderstorm from './images/thunderstorm.jpg';
import cloudy from './images/cloudy2.jpg';
import scattered from './images/scattered1.jpg';
import few from './images/few.webp';
import overcast from './images/overcast.jpg';
import haze1 from './images/haze.jpg';
import smoke from './images/smoke.jpg';
import mist from './images/haze2.jpg';
import fallback from './images/original3.jpg';

const WeatherTips = ({ currentWeather }) => {
  const tips = {
    clear: {
      tip: "It’s a sunny day! Perfect for sunglasses, sunscreen, and sipping something cold. Maybe even consider a beach day—but don’t forget, hydration is key!",
      image: clear,
    },

    scattered: {
      tip: "Scattered clouds! Perfect for outdoor activities, like teaching the kids how to ride a bicycle. Maybe even consider a beach day—but don’t forget, hydration is key!",
      image: scattered,
    },

    few: {
      tip: "Few clouds! Perfect for a light jacket, and taking the dog for a walk. Maybe even consider a beach day—but don’t forget, hydration is key!",
      image: few,
    },

    overcast: {
      tip: "It’s cloudy and gray, just like a cozy sweater’s best friend! Grab a warm drink, wear that comfy hoodie, and embrace the chill vibes.",
      image: overcast,
    },
    smoke: {
      tip: "With smoke in the air, today is not the day for a marathon! Stay inside, close windows, and if you must venture out, channel your inner bandit with a mask.",
      image: smoke,
    },
    rain: {
      tip: "Rainy day! Get your boots and umbrella, or better yet, pretend you're in a dramatic movie scene. Avoid puddles unless you’re secretly a duck!",
      image: rain,
    },
    snow: {
      tip: "Snow day! Time for layers on layers. It’s a great excuse to wear that fluffy scarf, or maybe build a snowman if you’re feeling like a kid again.",
      image: snow,
    },
    thunderstorm: {
      tip: "Thunderstorms are not for the faint-hearted! Best to stay indoors, avoid metal objects, and consider binge-watching some epic movies.",
      image: thunderstorm,
    },
    cloudy: {
      tip: "Cloudy with a chance of procrastination. Grab a light jacket and enjoy the excuse to stay indoors guilt-free. A great day for pondering life’s mysteries!",
      image: cloudy,
    },
    haze1: {
      tip: "Hazy skies call for keeping it indoors. Avoid heavy outdoor activities, keep windows closed, and try not to squint too hard at the horizon.",
      image: haze1,
    },
    mist: {
      tip: "Mist means mystery! If you’re out and about, drive carefully and maybe pretend you’re in a suspense film. Bring some hot cocoa along just in case.",
      image: mist,
    },
  };

  const normalizedWeather = currentWeather.toLowerCase();
  const matchedKey = Object.keys(tips).find((key) => normalizedWeather.includes(key));

  const { tip, image } = matchedKey
    ? tips[matchedKey]
    : { tip: "Weather's being shy! Prepare for anything: sunglasses, umbrella, maybe even a canoe. You never know!", image: fallback };

  return (
    <div className="tips-wrapper">
      <div className="tip-card">
        <div className="tip-image-wrapper">
          <img src={image} alt={currentWeather} className="tip-image" />
        </div>
        <div className="tip-text-wrapper">
          <p className="tip-text">{tip}</p>
        </div>
      </div>
      <div className="tips-description">
        <h3>About Our Weather Tips</h3>
        <p>
          Discover personalized tips tailored to the day’s weather conditions! Whether it’s sunny, stormy, or anything in between, our Weather Tips
          section provides insights and suggestions to help you make the most of each type of weather. From wardrobe tips to activity ideas,
          we’ve got you covered!
        </p>
      </div>
    </div>
  );
};

export default WeatherTips;
