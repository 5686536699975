// Footer.js
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faExclamationTriangle, faInfoCircle, faConciergeBell, faPhone } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import './Footer.css';

function Footer() {
    const currentYear = new Date().getFullYear();

    return (
        <footer className="footer-container">
            <div className="footer-content">

                {/* Links Section */}
                <div className="footer-section nav-section">
                    <ul>
                        <li><Link to="/"><FontAwesomeIcon icon={faHome} /><span>Home</span></Link></li>
                        <li><Link to="/weatheralerts"><FontAwesomeIcon icon={faExclamationTriangle} /><span>Weather Alerts</span></Link></li>
                        <li><Link to="/About"><FontAwesomeIcon icon={faInfoCircle} /><span>About Us</span></Link></li>
                        <li><Link to="/Services"><FontAwesomeIcon icon={faConciergeBell} /><span>Services</span></Link></li>
                        <li><Link to="/contact"><FontAwesomeIcon icon={faPhone} /><span>Contact Us</span></Link></li>
                    </ul>
                </div>

                {/* Affiliates Section */}
                <div className="footer-section links-section">
                    <ul>
                        <li><Link to="http://ww.icuzambia.net">ICU Zambia</Link></li>
                        <li><Link to="https://www.zrdc.org">ZRDC</Link></li>
                    </ul>
                </div>

                {/* Copyright */}
                <div className="footer-section copy-section">
                    <p>&copy; Copyright Zambezi Central. All Rights Reserved {currentYear}</p>
                    <p>Designed and maintained by: Kinaka Kikatula, 2310312352</p>
                </div>
                {/* Bottom Decorative Line */}
            <div className="footer-bottom-line"></div>
            </div>

        </footer>
    );
}

export default Footer;
