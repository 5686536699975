// HeroSection.js
import React from 'react';
import './Hero.css';
import { FaThermometerHalf } from 'react-icons/fa';

const HeroSection = ({ temperature, unit, convertToFahrenheit, convertToKelvin }) => {
  return (
    <div className="hero-container">
      <h1>{temperature !== null ? `${temperature} ${unit}` : "Enter city to get weather"}</h1>
      
      <div className="button-container">
        <button 
          onClick={convertToFahrenheit} 
          disabled={temperature === null}
          className={`convert-button ${temperature === null ? 'disabled' : ''}`}>
          <FaThermometerHalf className='icon' /> Fahrenheit
        </button>
        <button 
          onClick={convertToKelvin} 
          disabled={temperature === null}
          className={`convert-button ${temperature === null ? 'disabled' : ''}`}>
          <FaThermometerHalf className="icon" /> Kelvin
        </button>
      </div>
    </div>
  );
};

export default HeroSection;
